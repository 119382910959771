import m from 'mithril'
import {Amount, Spinner, Tippy} from '@bitstillery/common/components'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {current_account_slug} from '@bitstillery/common/account/account'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {Link} from '../discover'

import {$s} from '@/app'
import {SalesApi} from '@/factserver_api/sales_api'
import {SalesOrderItemsForItemResponse} from '@/factserver_api/fact2server_api'

export interface ItemSalesOrderAttrs {
    item_artkey: number
}

export class ItemSalesOrders extends MithrilTsxComponent<ItemSalesOrderAttrs> {
    sales_api = new SalesApi()
    is_loading = true
    sales_order_items: SalesOrderItemsForItemResponse[] = []
    limit = 10

    constructor(vnode: m.Vnode<ItemSalesOrderAttrs>) {
        super()
        this._fetch_sales_order_items(vnode.attrs.item_artkey)
    }

    _fetch_sales_order_items(item_artkey: number): void {
        this.is_loading = true

        this.sales_api.api.get<SalesOrderItemsForItemResponse[]>(
            `discover/items/${item_artkey}/sales-order-items?limit=${this.limit}`,
        ).toPromise().then(
            (response) => {
                this.sales_order_items = response
                this.is_loading = false
                m.redraw()
            },
        )
    }

    view(): m.Children {
        return (
            <div className={'panel panel-default'}>
                <div className={'panel-heading'}>
                    <div className={'panel-title'}>Latest sales orders for this specific lot</div>
                </div>
                <div className={'panel-body'}>
                    {this.sales_order_items.length === 0 ? (
                        <div class="c-empty-message">{this.is_loading ? <Spinner /> : 'Not in any sales order.'}</div>
                    ) : (
                        <div>
                            <table className={'table table-condensed'}>
                                <thead className="thead-default">
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Relation</th>
                                        <th>€ / cs</th>
                                        <th>Quantity</th>
                                        <th>Status</th>
                                        <th>Handled by</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.sales_order_items.map((sales_order_item) => (
                                        <tr>
                                            <td>
                                                <Link
                                                    target="_blank"
                                                    href={`/sales/orders/${sales_order_item.sales_order_artkey}/view/manage`}
                                                >
                                                    {sales_order_item.sales_order_reference}
                                                </Link>
                                            </td>
                                            <td>{format_iso_to_date(sales_order_item.sales_order_created_on)}</td>
                                            <td className="ellipsis">
                                                {sales_order_item.account_slug === current_account_slug() ? (
                                                    sales_order_item.supplier_name
                                                ) : (
                                                    <span>
                                                        <Tippy
                                                            content={
                                                                'End client: ' +
                                                                sales_order_item.account_name
                                                            }
                                                        >
                                                            {sales_order_item.supplier_name}
                                                        </Tippy>
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                <Amount
                                                    amount={sales_order_item.price_per_case_excl_excise}
                                                    currency={sales_order_item.sales_order_was_sold_in}
                                                    rate={+sales_order_item.sales_order_sold_against_rate}
                                                    display_currency={$s.currencies.default}
                                                />
                                            </td>
                                            <td>{sales_order_item.number_of_cases}</td>
                                            <td>{sales_order_item.sales_order_status}</td>
                                            <td>{sales_order_item.was_handled_by_name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
