import {ContextProvider, ContextProviderDataGeneric} from '@bitstillery/common/lib/context'
import {api, logger, notifier} from '@bitstillery/common/app'
import {EntityCommon} from '@bitstillery/common/lib/context'
import {copy_object, merge_deep} from '@bitstillery/common/lib/utils'
import {
    bottle_gtin,
    conditional,
    required,
    validation,
} from '@bitstillery/common/lib/validation'

import {
    BottleByGTINResp,
    CustomsStatus,
    EntityAlt,
    GetProductCategorySelectResponse,
    GetSupplierPriceListItemCollectionResponse,
    GetSupplierPriceListItemResponse,
    GiftBoxType,
    SuggestionsResponse,
    SupplierPriceListItemRequest,
    SupplierPriceListResponse,
} from '@/factserver_api/fact2server_api'
import {collection as collection_spli} from '@/market/pricelists/view/lib/collection_spli'
import {collection as collection_splsl} from '@/market/pricelists/view/lib/collection_splsl'
import {collection as collection_splsl_suggestions} from '@/market/pricelists/view/lib/collection_splsl_suggestions'
/** Augmented with clientside non-meta entities */
export enum EntityType {
    DATA_CARD = 'data_card',
    CUSTOM_OFFER = 'custom_offer',
    PURCHASE_ORDER = 'purchase_order',
    SPLSL = EntityAlt.SPLSL,
    SPLI = EntityAlt.SPLI,
}

export type ProductContext = {
    alcohol_percentage?: string
    bottle_artkey?: number
    country_of_origin: string
    product_artkey: number | null
    product_name: string
    product_category_artkey?: number
    product_category_name: string
    refill_status?: string
    volume?: string
}

// Map directly to meta endpoints.
const collections = {
    [EntityAlt.SPLI]: collection_spli,
    [EntityAlt.SPLSL]: collection_splsl,
}

const pricelist_item_defaults = {
    artkey: null! as number,
    aux_info: '',
    alcohol_percentage: '',
    /** SPLSL-only field */
    availability_status: '',
    bottle_artkey: null! as number,
    bottle_gtin_code: null,
    case_gtin_code: '',
    country_of_origin: '',
    currency: '',
    customs_status: ''! as CustomsStatus,
    gift_box_type: ''! as GiftBoxType,
    incoterm: '',
    incoterm_location: '',
    /** Entity property */
    meta: false,
    number_of_bottles_per_case: null! as number,
    number_of_bottles: null! as number,
    number_of_cases: null! as number,
    per_case: true,
    previous_number_of_bottles_per_case: 6,
    price_per_bottle: null,
    price_per_case: null! as number,
    product_artkey: null,
    product_category_artkey: null! as number,
    product_name: '',
    /** Entity property */
    reference: '',
    refill_status: '',
    /** Entity property */
    title: '',
    volume: '',

    cases_per_pallet: null,
    cases_per_pallet_layer: null,
    case_cases_per_pallet: null,
    case_cases_per_pallet_layer: null,
    trusted_supplier_cases_per_pallet: null,
    trusted_supplier_cases_per_pallet_layer: null,
}

// Legacy model for AddToOrder selected_spli
const purchase_order_model = {
    artkey: null! as number,
    bottle: {
        artkey: null,
        product: {
            default_country_code: '',
            name: '',
        },
    },
    cases_per_pallet: null,
    cases_per_pallet_layer: null,
    // Broken...
    case_cases_per_pallet: null,
    case_cases_per_pallet_layer: null,
    trusted_supplier_cases_per_pallet: null,
    trusted_supplier_cases_per_pallet_layer: null,
    // / Broken...
    aux_info: '',
    incoterm: '',
    price_per_case: null,
    price_per_bottle: null,
    number_of_cases: null,
    availability_status: null,
    case_gift_box_type: null,
    currency: '',
    customs_status: '',
    number_of_bottles: null,
    number_of_bottles_per_case: null,
    supplier: {
        artkey: null,
        name: '',
    },
    supplier_price_list: {
        artkey: null,
        supplier: {
            name: null,
        },
    },
}

const custom_offer_model = {
    bottle_artkey: null,
    cases_per_pallet: null,
    cases_per_pallet_layer: null,
    customs_status: '',
    gift_box_type: '',
    incoterm: 'EXW',
    incoterm_location: '',
    item_tags: [],
    offers: [],
    number_of_bottles: null,
    tax_label: '',
}

export type EntitySpli = typeof pricelist_item_defaults
export type EntitySplsl = typeof pricelist_item_defaults

const data = {
    bottles: [] as any[],
    entity_artkey: null,
    entity_type: '' as EntityType,
    entities: {
        custom_offer: {...custom_offer_model, meta: false, reference: '', title: 'Custom Offer'} as EntityCommon,
        data_card: {meta: false, reference: '', title: 'DataCard'} as EntityCommon,
        purchase_order: {
            ...purchase_order_model,
            meta: false, reference: '',
            title: 'Purchase Order',
        } as EntityCommon,
        [EntityType.SPLI]: {...copy_object(pricelist_item_defaults), title: 'SPLI'} as EntitySpli,
        [EntityType.SPLSL]: {...copy_object(pricelist_item_defaults), title: 'SPLSL'} as EntitySplsl,
    },
    has_suggestions: false,
    linked: {
        gtin: false,
        specs: false,
    },
    loading: {
        export: false,
        page: false,
        pricelist_item: false,
    },
    product_categories: [] as GetProductCategorySelectResponse[],
    search_options: [],
    supplier_pricelist: null! as GetSupplierPriceListItemCollectionResponse,
    root_artkey: null!,
    root_path: '/market/pricelists/{:root_artkey}/view/manage',
} satisfies ContextProviderDataGeneric

export type ViewContextData = typeof data

export const context = new ContextProvider<ViewContextData>({
    data,
    edit_details: {
        SPLI: false,
        SPLSL: true,
    },
    name: 'market.pricelists.view',
    route: {
        match: '/market/pricelists/{:root_artkey}/view{/:tab_id}{/:entity_type}{/:entity_artkey}',
        root: '/market/pricelists/{:root_artkey}/view/manage',
    },
    transforms: {
        bootstrap: async() => {
            const [{result: product_categories}, {result: supplier_pricelist}] = await Promise.all([
                api.get<GetProductCategorySelectResponse>('discover/product-categories/select'),
                api.get<GetSupplierPriceListItemCollectionResponse>(`discover/supplier-price-lists/${context.data.root_artkey}`),
            ])

            merge_deep(context.data, {product_categories, supplier_pricelist})

            for (const entity of [context.data.entities[EntityType.SPLI], context.data.entities[EntityType.SPLSL]]) {
                // Fill in blanks from pricelist defaults
                merge_deep(entity, {
                    currency: supplier_pricelist.default_currency,
                    customs_status: supplier_pricelist.default_customs_status,
                    incoterm: supplier_pricelist.default_incoterm,
                    incoterm_location: supplier_pricelist.default_incoterm_location,
                    number_of_bottles_per_case: 6,
                })
            }
            // Save the default context data.
            context.bootstrap_data()
        },
        fetch_entity: async() => {
            if (![
                EntityType.DATA_CARD,
                EntityType.SPLI,
                EntityType.SPLSL,
                EntityType.CUSTOM_OFFER,
                EntityType.PURCHASE_ORDER,
            ].includes(context.data.entity_type)) {
                return
            }

            let entity, collection

            if ([
                EntityType.CUSTOM_OFFER,
                EntityType.PURCHASE_ORDER,
                EntityType.SPLI,
            ].includes(context.data.entity_type)) {
                // Entities that depend on SPLI data.
                collection = collection_spli
                entity = context.bootstrapped_entity(EntityType.SPLI)
                await collection.is_ready()

                const endpoint = `discover/supplier-price-lists/${context.data.root_artkey}/supplier-price-list-items/${context.data.entity_artkey}`
                const {result:spli} = await api.get<GetSupplierPriceListItemResponse>(endpoint)
                merge_deep(entity, spli)

                const {result: bottles} = await api.get(`discover/products/${entity.product_artkey}/bottles`)
                context.data.bottles.splice(0, context.data.bottles.length, ...bottles)
                merge_deep(context.data.linked, {
                    gtin: !!entity.bottle_gtin_code,
                    specs: true,
                })

                if (context.data.entity_type === EntityType.CUSTOM_OFFER) {
                    await methods.to_entity_custom_offer()
                    return {collection, entity}
                }
                // Make sure the entity data for PO is ready; because switching
                // between entity_types with the same artket won't trigger
                // another fetch_entity.
                if (context.data.entity_type === EntityType.PURCHASE_ORDER) {
                    return {collection, entity: methods.to_entity_purchase_order(entity)}
                }
            } else if (context.data.entity_type === EntityType.SPLSL) {
                collection = collection_splsl
                entity = context.bootstrapped_entity(EntityType.SPLSL)
                await collection.is_ready()

                const endpoint = `discover/supplier-price-lists/${context.data.root_artkey}/source-lines/${context.data.entity_artkey}`
                const {result: splsl} = await api.get(endpoint)
                merge_deep(entity, splsl)
                await methods.fetch_suggestions(entity)
            } else {
                collection = collection_spli
                entity = context.bootstrapped_entity(context.data.entity_type)
            }

            if (entity.number_of_bottles) {
                merge_deep({number_of_cases: null, per_case: false, price_per_case: null})
            } else {
                merge_deep({number_of_cases: null, per_case: true, price_per_case: null})
            }

            // Store this separately to be able to toggle between bottles & cases amount, without rounding issues.
            entity.previous_number_of_bottles_per_case = entity.number_of_bottles_per_case

            // The incoterm (e.g. DAP) and location are one field and must be splitted for editing and rejoined when saving.
            if ('incoterm' in entity && entity.incoterm.includes('-')) {
                const [incoterm, ...location] = entity.incoterm.split('-').map((i) => i.trim())
                entity.incoterm = incoterm
                entity.incoterm_location = location.join(' ')
            }
            return {collection, entity}
        },
        fetched_entity: () => {
            if (context.data.entity_type === EntityType.PURCHASE_ORDER) {
                context.data.entities[EntityType.PURCHASE_ORDER].update_model()
            }
        },
        reset_entity: async() => {
            context.data.bottles.splice(0, context.data.bottles.length)
            merge_deep(context.data.linked, {
                gtin: false,
                specs: false,
            })
        },
    },
    validation: () => {
        return {
            [EntityType.SPLI]: methods.entity_type_validation(EntityType.SPLI),
            [EntityType.SPLSL]: methods.entity_type_validation(EntityType.SPLSL),
        }
    },
})

export const methods = {
    /**
     * EntitySpli & EntitySplsl share the same form (pricelist_item) & validation
     */
    entity_type_validation(entity_type: EntityType) {
        const entity = context.data.entities[entity_type] as EntitySpli | EntitySplsl
        return {
            alcohol_percentage: validation(
                [entity, 'alcohol_percentage'],
                conditional(() => !context.data.linked.specs, required()),
            ),
            bottle_gtin_code: validation([entity, 'bottle_gtin_code'], bottle_gtin()),
            refill_status: validation(
                [entity, 'refill_status'],
                conditional(() => !context.data.linked.specs, required()),
            ),
            volume: validation(
                [entity, 'volume'],
                conditional(() => !context.data.linked.specs, required()),
            ),
            country_of_origin: validation([entity, 'country_of_origin'], required()),
            currency: validation([entity, 'currency'], required()),
            customs_status: validation([entity, 'customs_status'], required()),
            number_of_bottles_per_case: validation([entity, 'number_of_bottles_per_case'], required()),
            price_per_bottle: validation(
                [entity, 'price_per_bottle'],
                conditional(() => !entity.per_case, required()),
            ),
            price_per_case: validation(
                [entity, 'price_per_case'],
                conditional(() => entity.per_case, required()),
            ),
            product_name: validation([entity, 'product_name'], required()),
            product_category_artkey: validation([entity, 'product_category_artkey'], required()),
        }
    },
    async match_from_gtin(entity:EntitySpli | EntitySplsl) {
        logger.debug('[market.pricelists.view] context from bottle gtin', data)
        context.data.loading.pricelist_item = true
        const {result: bottle, status_code}: {result: BottleByGTINResp; status_code: number} = await api.get(`discover/bottle-gtin/${entity.bottle_gtin_code}/bottle`)
        if (status_code === 404) {
            notifier.notify('Sorry, we don\'t know about this bottle GTIN yet', 'warning')
            context.data.loading.pricelist_item = false
            return
        }

        await this.match_from_data({
            alcohol_percentage: bottle.alcohol_percentage,
            bottle_artkey: bottle.artkey,
            country_of_origin: bottle.default_country_code,
            name: bottle.product_name,
            product_artkey: bottle.product_artkey,
            product_category_artkey: bottle.product_category_artkey,
            product_category_name: bottle.product_category_name,
            product_name: bottle.product_name,
            refill_status: bottle.refill_status,
            volume: bottle.volume,
        }, [bottle], entity)

        merge_deep(context.data.linked, {gtin: true, specs: true})
        context.data.loading.pricelist_item = false
    },

    async match_from_data(product_data: ProductContext, bottles: any[], entity:EntitySpli | EntitySplsl) {
        merge_deep(entity, product_data)

        if (bottles.length) {
            context.data.bottles.splice(0, context.data.bottles.length, ...(bottles as any[]))
            context.data.bottles.sort((a: any, b: any) => { return +b.volume - +a.volume })

            const matching_bottle: any[] = bottles.filter((bottle: any) => {
                return bottle.volume === entity.volume
                && bottle.alcohol_percentage === entity.alcohol_percentage
                && bottle.refill_status === entity.refill_status
            })

            if (matching_bottle.length === 1) {
                // Only one bottle found, use it.
                const bottle = matching_bottle[0]
                merge_deep(entity, {
                    alcohol_percentage: bottle.alcohol_percentage,
                    bottle_artkey: bottle.artkey,
                    country_of_origin: product_data.country_of_origin,
                    product_category_artkey: product_data.product_category_artkey,
                    refill_status: bottle.refill_status,
                    volume: bottle.volume,
                })
            } else {
                // 0 or more than 1 found; manual selection needed.
                merge_deep(entity, {bottle_artkey: null})
            }
        }

        merge_deep(context.data.linked, {
            gtin: false,
            specs: !!entity.bottle_artkey,
        })

    },
    async to_entity_custom_offer() {
        merge_deep(context.data.entities[EntityType.CUSTOM_OFFER], context.data.entities[EntityType.SPLI], {
            // tax_label: '',
            // Extra
            best_before_date: null,
            delivery_period: null,
            maximum_quantity: null,
            minimum_quantity: null,
            offer_artkey: null,
            sales_price_per_case: null,
            supplier: context.data.supplier_pricelist.supplier,
        })
    },
    to_entity_purchase_order(spli: EntitySpli) {
        const entity_purchase_order = context.bootstrapped_entity(EntityType.PURCHASE_ORDER)
        merge_deep(entity_purchase_order, {
            artkey: spli.artkey,
            bottle: {
                artkey: spli.bottle_artkey,
                product: {
                    default_country_code: spli.country_of_origin,
                    name: spli.product_name,
                },
                volume: spli.volume,
                alcohol_percentage: spli.alcohol_percentage,
                refill_status: spli.refill_status,
            },
            cases_per_pallet: spli.cases_per_pallet,
            cases_per_pallet_layer: spli.cases_per_pallet_layer,
            case_cases_per_pallet: spli.case_cases_per_pallet,
            case_cases_per_pallet_layer: spli.case_cases_per_pallet_layer,
            trusted_supplier_cases_per_pallet: spli.trusted_supplier_cases_per_pallet,
            trusted_supplier_cases_per_pallet_layer: spli.trusted_supplier_cases_per_pallet_layer,
            aux_info: spli.aux_info,
            incoterm: spli.incoterm,
            price_per_case: spli.price_per_case,
            price_per_bottle: spli.price_per_bottle,
            number_of_cases: spli.number_of_cases,
            availability_status: spli.availability_status,
            case_gift_box_type: spli.gift_box_type,
            currency: spli.currency,
            customs_status: spli.customs_status,
            number_of_bottles: spli.number_of_bottles_per_case * (spli.number_of_cases ?? 1),
            number_of_bottles_per_case: spli.number_of_bottles_per_case,
            supplier_price_list: {
                artkey: context.data.supplier_pricelist.artkey,
                supplier: {
                    name: context.data.supplier_pricelist.supplier.name,
                },
            },
            supplier: {
                artkey: context.data.supplier_pricelist.supplier.artkey,
                name: context.data.supplier_pricelist.supplier.name,
            },
        })

        return entity_purchase_order
    },
    async fetch_pricelist() {
        const endpoint = `discover/supplier-price-lists/${context.data.supplier_pricelist.artkey}`
        const {result} = await api.get<SupplierPriceListResponse>(endpoint)
        merge_deep(context.data.supplier_pricelist, result)
    },
    async fetch_suggestions(entity: EntitySplsl, discover_only = false, product_name?: string) {
        logger.debug(`[resolve] fetching splsl suggestions: ${entity.product_name}`)

        let disco_suggestions: SuggestionsResponse[]
        let dg_suggestions: SuggestionsResponse[] = []

        const discover_suggestions_endpoint = `discover/supplier-price-lists/${context.data.supplier_pricelist.artkey}/source-lines/${entity.artkey}/discover-suggestions`
        const dg_suggestions_endpoint = `discover/supplier-price-lists/${context.data.supplier_pricelist.artkey}/source-lines/${entity.artkey}/dg-suggestions`

        if (discover_only) {
            ({result: disco_suggestions} = await api.get<SuggestionsResponse[]>(discover_suggestions_endpoint, {product_name}))
        } else {
            [{result: disco_suggestions}, {result: dg_suggestions}] = await Promise.all([
                api.get<SuggestionsResponse[]>(discover_suggestions_endpoint, {product_name}),
                api.get<SuggestionsResponse[]>(dg_suggestions_endpoint),
            ])
        }

        const suggestions: SuggestionsResponse[] = [...disco_suggestions, ...dg_suggestions].sort(
            (a, b) => {
                // First sort by entity_alt in specific order
                if (a.entity_alt !== b.entity_alt) {
                    // Define specific order: PROD_SUGGESTION first, then DG_SUGGESTION, then GOOGLE_SUGGESTION
                    const entity_order = {
                        [EntityAlt.PROD_SUGGESTION]: 1,
                        [EntityAlt.DG_SUGGESTION]: 2,
                    }
                    return entity_order[a.entity_alt] - entity_order[b.entity_alt]
                }
                // Then sort by ratio in descending order
                return +(a.ratio) > +(b.ratio) ? -1 : 1
            },
        )

        collection_splsl_suggestions.state.items.splice(0, collection_splsl_suggestions.state.items.length, ...suggestions)
        context.data.has_suggestions = collection_splsl_suggestions.state.items.length > 0
    },
    async upsert_entity(entity_type: EntityType.SPLI | EntityType.SPLSL) {
        context.data.loading.pricelist_item = true
        const entity = context.data.entities[entity_type] as EntitySpli | EntitySplsl

        const pricelist_item_data: SupplierPriceListItemRequest = {
            source_line_artkey: entity_type === EntityType.SPLSL ? entity.artkey : null,
            cases_per_pallet_layer: entity.cases_per_pallet_layer || null,
            cases_per_pallet: entity.cases_per_pallet || null,
            aux_info: entity.aux_info,
            availability_status: entity.availability_status,
            bottle: {
                alcohol_percentage: entity.alcohol_percentage,
                artkey: entity.bottle_artkey || null,
                product: {
                    artkey: entity.product_artkey,
                    category: {
                        artkey: entity.product_category_artkey,
                    },
                    default_country_code: entity.country_of_origin,
                    name: entity.product_name,
                },
                refill_status: entity.refill_status || null,
                volume: entity.volume,
            },
            bottle_gtin_code: entity.bottle_gtin_code || null,
            country_of_origin: entity.country_of_origin,
            case_gtin_code: entity.case_gtin_code,
            currency: entity.currency,
            customs_status: entity.customs_status,
            gift_box_type: entity.gift_box_type || null,
            incoterm: entity.incoterm_location ? `${entity.incoterm} - ${entity.incoterm_location}` : entity.incoterm,
            number_of_bottles: entity.number_of_bottles || null,
            number_of_bottles_per_case: entity.number_of_bottles_per_case,
            number_of_cases: entity.number_of_cases || null,
            price_per_bottle: entity.price_per_bottle,
            price_per_case: entity.price_per_case,
            supplier_price_list_artkey: context.data.supplier_pricelist.artkey,
        }

        let success, result
        const endpoint_prefix = collections[entity_type].entities[entity_type].endpoint
        if (entity_type === EntityType.SPLSL) {
            ({result, success} = await api.post(endpoint_prefix, pricelist_item_data, true))
            const artkey = entity.artkey
            if (success) {
                context.select_next(collections[EntityType.SPLSL])
                // The SPLSL item is removed and added to the SPLI collection.
                collections[EntityType.SPLSL].soft_delete(artkey)
            }
        } else if (entity_type === EntityType.SPLI) {
            if (entity.artkey) {
                pricelist_item_data.artkey = entity.artkey;
                ({result, success} = await api.put<SupplierPriceListItemRequest>(
                    `${endpoint_prefix}/${entity.artkey}`, pricelist_item_data,
                ) as any)
                await collections[entity_type].update_item(entity.artkey)
                context.select_next(collections[EntityType.SPLI])
            } else {
                ({result, success} = await api.post(endpoint_prefix, pricelist_item_data, true))
                // Create a new spli item; clear the entity data for the next item.
                context.reset_entity(entity_type)
                await collections[entity_type].reset_query()
            }
        }

        context.data.loading.pricelist_item = false

        if (success) {
            notifier.notify(`Successfully ${entity.artkey ? 'updated' : 'created'} ${entity_type}`, 'success')
        } else {
            notifier.notify(`An error occured while ${entity.artkey ? 'updating' : 'creating'} ${entity_type}:<br/>${result.detail}`, 'danger')
            return
        }

        await methods.fetch_pricelist()
    },
}
