import m from 'mithril'
import {api} from '@bitstillery/common/app'
import {notifier} from '@bitstillery/common/app'
import {and, conditional, greater_than_or_equal_to, required, validation} from '@bitstillery/common/lib/validation'
import {logger} from '@bitstillery/common/app'
import {ContextProvider, ContextProviderDataGeneric} from '@bitstillery/common/lib/context'
import {merge_deep} from '@bitstillery/common/lib/utils'
import {EntityCommon} from '@bitstillery/common/lib/context'
import {CasesOrBottles} from '@bitstillery/common/pdf/pdf'
import {proforma_or_invoice_renderer, ProformaInvoice} from '@bitstillery/common/pdf/proforma_invoice_renderer'
import {ProformaInvoiceRendererAttrs} from '@bitstillery/common/pdf/proforma_invoice_renderer'
import {SalesOrder} from '@bitstillery/common/models/sales_order'

import {collection as collection_so} from '@/sales/orders/view/lib/collection_sales_order'
import {collection as collection_sellable} from '@/sales/orders/view/lib/collection_sellable'
import {GetFastSalesOrderWithItemsResponse, LoenderslootOuttakeResponse} from '@/factserver_api/sales_api'
import {LoenderslootFlow} from '@/sales/models'
import {
    CombinedOrderStatus,
    GetSalesOrderResponse,
    GetRelationResponse,
    GetRelationLogBookEntryResponse,
    GetSalesOrderItemsCollectionViewResponse,
    GetBuyFromAccountRelatedSalesOrderResponse,
    GetBuyFromAccountPurchaseOrderResponse,
    GetBuyFromAccountSalesOrderResponse,
    SalesOrderAdditionalType,
    GetSalesOrderCreditItemResponse,
    GetSalesOrderTBOItemResponse,
    GetSalesOrderVoucherResponse,
    GetSalesOrderAdditionalResponse,
    GetActiveVoucherResponse,
    GetLotAvailabilityResponse,
    GetCountryOfOriginAvailabilitiesResponse,
} from '@/factserver_api/fact2server_api'
import {SellableItemFilterType} from '@/factserver_api/fact2server_api'
import {GetSalesOrderItemResponse} from '@/factserver_api/fact2server_api'
import {max_number_of_cases} from '@/sales/orders/view/manage/context/stock_item'
import {PDFHelper} from '@/components/pdf_helper'

export enum EntityType {
    SOA = 'SOA',
    SOBI = 'SOBI',
    SOCI = 'SOCI',
    SOI = 'SOI',
    SOTI = 'SOTI',
    VOUCHER = 'VOUCHER',
    DATA_CARD = 'data_card',
}

export interface EntitySOI extends GetSalesOrderItemResponse, EntityCommon {
    country_of_origin: string | null
    euro_was_bought_for: string
    list_price: number
    minimum_order_quantity?: boolean
    number_of_cases_available: number
    /** A new entity being filled from a sellable item */
    reference: string
    sellable_item_type?: SellableItemFilterType
}
export interface EntitySOTI extends GetSalesOrderTBOItemResponse, EntityCommon {
    number_of_cases_available: number
    /** Sellable property; must be available with POST */
    offer_item_artkey?: number
    /** The type of sellable item we want to add to the sales order */
    sellable_item_type?: SellableItemFilterType
    /** Sellable property; must be available with POST */
    supplier_price_list_item_artkey?: number
    /** Sellable property; must be available with POST */
    purchase_order_item_artkey?: number
}
export interface EntitySOA extends GetSalesOrderAdditionalResponse, EntityCommon {
    /** A reference to a sellable item */
    reference: string
}
export interface EntitySOCI extends GetSalesOrderCreditItemResponse, EntityCommon {
    /** A reference to a sellable item */
    reference: string
    sales_order_item_artkey: number
}
export interface EntityVoucher extends GetSalesOrderVoucherResponse, EntityCommon {}
export interface EntitySOBI extends EntityCommon {
    sellable_item_type: SellableItemFilterType
}

const data = {
    active_vouchers: [] as GetActiveVoucherResponse[],
    buy_from_account: {
        purchase_orders: [] as GetBuyFromAccountPurchaseOrderResponse[],
        sales_orders: [] as GetBuyFromAccountSalesOrderResponse[],
    },
    countries_of_origin: [] as GetCountryOfOriginAvailabilitiesResponse[],
    dialog: {
        download_photos: false,
        confirm_settings: false,
        sent_to_loendersloot: false,
    },
    entity_artkey: null,
    entity_type: '' as EntityType,
    entities: {
        data_card: {meta: false, reference: '', title: 'DataCard'} as EntityCommon,
        SOA: {meta: true, reference: '', title: 'Additional Item'} as EntitySOA,
        SOBI: {meta: true, reference: '', title: 'Basket Item'} as EntitySOBI,
        SOCI: {meta: true, reference: '', title: 'Credit Item'} as EntitySOCI,
        SOI: {
            meta: true,
            reference: '',
            title: 'Sales Order Item',
        } as EntitySOI,
        SOTI: {meta: true, reference: '', title: 'TBO Item'} as EntitySOTI,
        VOUCHER: {meta: true, reference: '', title: 'Voucher'} as EntityVoucher,
    },
    loendersloot_flow: LoenderslootFlow.OUTBOUND,
    loendersloot_outtake: null! as LoenderslootOuttakeResponse,
    lots: [] as GetLotAvailabilityResponse[],
    photos_is_zipping: false,
    proforma: {
        group_similar_items: false,
        sales_order: {}! as GetFastSalesOrderWithItemsResponse,
        show_article_code: false,
        show_bottle_lot: false,
        show_cbs_code: true,
        show_decimal_point_as: 'en' as string,
        show_country_of_origin: true,
        show_liters_of_alcohol: false,
        show_in_cases_or_bottles: null! as CasesOrBottles,
    },
    relation: null! as GetRelationResponse,
    relation_log_entries: [] as GetRelationLogBookEntryResponse[],
    root_artkey: null!,
    root_path: '/sales/orders/{:root_artkey}/view/manage',
    sales_order_artkey: null! as number,
    sales_order: null! as GetSalesOrderResponse,
    /** The sales order items in the panel overview; sorted on last_modified */
    sales_order_items_summary: [] as GetSalesOrderItemsCollectionViewResponse[],
    stepper: {
        active: null! as CombinedOrderStatus,
        current: 0,
    },
} satisfies ContextProviderDataGeneric

export type ViewContextData = typeof data

export const context = new ContextProvider<ViewContextData>({
    data,
    name: 'sales.orders.view',
    // (!) :tab_id because the context is also active when navigating to the other tabs.
    route: {
        match: '/sales/orders/{:root_artkey}/view{/:tab_id}{/:entity_type}{/:entity_artkey}',
        root: '/sales/orders/{:root_artkey}/view/manage',
    },
    transforms: {
        bootstrap: async(context) => {
            await methods.fetch_sales_order_with_items(context.data.root_artkey)

            await Promise.all([
                methods.fetch_buy_from_account_orders(context.data.root_artkey),
                methods.fetch_relation(),
                methods.fetch_active_vouchers(),
                methods.fetch_sales_order_items_summary(),
            ])
        },
        fetch_entity: async() => {
            await collection_so.is_ready()
            const {endpoint} = collection_so.entities[context.data.entity_type]
            // The artkeys like SOI-123 need to be stripped when accessing the entity endpoints.
            const clean_artkey = context.data.entity_artkey.split('-')[1]
            const {result: entity_data} = await api.get<GetSalesOrderItemsCollectionViewResponse>(`${endpoint}/${clean_artkey}`) as any
            const entity = context.bootstrapped_entity(context.data.entity_type)

            merge_deep(entity, entity_data, {artkey: context.data.entity_artkey})
            return {collection: collection_so, entity}
        },
        reset_entity: async() => {
            methods.fetch_sales_order_with_items(context.data.root_artkey)
            collection_so.select_none()
            collection_sellable.select_none()
        },
    },
    validation: () => {
        return {
            SOA: {
                description: validation([context.data.entities.SOA, 'description'], required()),
                quantity: validation([context.data.entities.SOA, 'quantity'], required()),
                value_per_quantity: validation([context.data.entities.SOA, 'value_per_quantity'], and([required(), {
                    validate: function(modelvalue) {
                        const entity = context.data.entities.SOA as EntitySOA
                        if (entity.sales_order_additional_type === SalesOrderAdditionalType.Discount) {
                            if (modelvalue > 0) {
                                this.message = 'Discount value must be negative'
                                return this
                            }
                        } else {
                            if (modelvalue < 0) {
                                this.message = 'Costs value must be positive'
                                return this
                            }
                        }

                        return false
                    },
                    label: '',
                    message: '',
                }])),
            },
            SOBI: {
                item_artkey: validation([context.data.entities.SOBI, 'item_artkey'],
                    conditional(() => context.data.entities.SOBI.sellable_item_type === SellableItemFilterType.Stock, required()),
                ),
                number_of_cases: validation([context.data.entities.SOBI, 'number_of_cases'], required()),
                price_per_case: validation([context.data.entities.SOBI, 'price_per_case'], required()),
            },
            SOCI: {
                number_of_cases: validation([context.data.entities.SOCI, 'number_of_cases'], required()),
                sales_order_item_artkey: validation([context.data.entities.SOCI, 'sales_order_item_artkey'], required()),
            },
            SOI: {
                number_of_cases: validation([context.data.entities.SOI, 'number_of_cases'], and([greater_than_or_equal_to(1), {
                    validate: function(model_value) {
                        const entity = context.data.entities.SOI as EntitySOI
                        if (entity.minimum_order_quantity && model_value < entity.minimum_order_quantity) {
                            this.message = `Minimum order quantity: ${entity.minimum_order_quantity}`
                            return this
                        }
                        if (model_value > max_number_of_cases(entity)) {
                            this.message = `Maximum cases available: ${max_number_of_cases(entity)}`
                            return this
                        }
                        return false
                    },
                    label: '',
                    message: '',
                }])),
                price_per_case: validation([context.data.entities.SOI, 'price_per_case'], required()),
            },
            SOTI: {
                offer_item_artkey: validation([context.data.entities.SOTI, 'offer_item_artkey'], conditional(() => {
                    // Only enforced when POSTING.
                    if (context.data.entities.SOTI.artkey) {
                        return false
                    }
                    return context.data.entities.SOTI.sellable_item_type === SellableItemFilterType.TBO
                }, required())),
                supplier_price_list_item_artkey: validation([context.data.entities.SOTI, 'supplier_price_list_item_artkey'], conditional(() => {
                    // Only enforced when POSTING.
                    if (context.data.entities.SOTI.artkey) {
                        return false
                    }

                    return context.data.entities.SOTI.sellable_item_type === SellableItemFilterType.Market
                }, required())),
                purchase_order_item_artkey: validation([context.data.entities.SOTI, 'purchase_order_item_artkey'], conditional(() => {
                    // Only enforced when POSTING or for SOTI (TBO).
                    if (context.data.entities.SOTI.artkey || context.data.entity_type === EntityType.SOI) {
                        return false
                    }
                    return context.data.entities.SOTI.sellable_item_type === SellableItemFilterType.Purchase
                }, required())),
                number_of_cases: validation([context.data.entities.SOTI, 'number_of_cases'], required()),
                price_per_case: validation([context.data.entities.SOTI, 'price_per_case'], required()),
            },
            VOUCHER: {
                artkey: validation([context.data.entities.VOUCHER, 'artkey'], required()),
            },
        }
    },
})

export const methods = {

    async fetch_active_vouchers() {
        const {status_code, result} = await api.get<GetActiveVoucherResponse[]>(`/discover/relations/${context.data.sales_order.supplier_artkey}/active-vouchers`)
        if (status_code > 299) {
            return
        }
        context.data.active_vouchers.splice(0, context.data.active_vouchers.length, ...result)
    },
    async fetch_buy_from_account_orders(sales_order_artkey: number) {
        const route_prefix = `discover/sales-orders/${sales_order_artkey}/buy-from-account`
        const [sales_orders, purchase_orders] = await Promise.all([
            api.get<GetBuyFromAccountRelatedSalesOrderResponse>(`${route_prefix}/sales-orders`),
            api.get<GetBuyFromAccountPurchaseOrderResponse>(`${route_prefix}/purchase-orders`),
        ])

        merge_deep(context.data, {
            buy_from_account: {
                purchase_orders: purchase_orders.result,
                sales_orders: sales_orders.result,
            },
        })
    },
    async fetch_sales_order_items_summary() {
        const query_string = new URLSearchParams({
            page_size: '200',
            sort_ascending: 'DESC',
            sort_by: 'was_last_updated_on_ungrouped',
        }).toString()
        const endpoint = `discover/sales-orders/${context.data.sales_order.artkey}/items/collection-view?${query_string}`
        const {result: sales_order_items_summary} = await api.get<GetSalesOrderItemsCollectionViewResponse[]>(endpoint)
        context.data.sales_order_items_summary.splice(0, context.data.sales_order_items_summary.length, ...sales_order_items_summary)
    },
    async fetch_relation() {
        const [relation, relation_log_entries] = await Promise.all([
            api.get<GetRelationResponse>(`discover/relations/${context.data.sales_order.supplier_artkey}`),
            api.get<GetRelationLogBookEntryResponse[]>(`discover/relations/${context.data.sales_order.supplier_artkey}/log-entries`),
        ]).then(([relation, log_entries]) => [relation.result, log_entries.result])
        merge_deep(context.data, {relation, relation_log_entries})
    },
    async fetch_sales_order_proforma(sales_order_artkey: number) {
        const {result, success} = await api.post<GetFastSalesOrderWithItemsResponse>('sales.core.get_fast_sales_order_with_items', {
            sales_order_artkey: sales_order_artkey,
            group_similar_items: this.group_similar_items,
        })

        if (!success) {
            notifier.notify(`Cannot load sales order with artkey ${context.data.sales_order.artkey}`, 'warning')
            m.route.set('/sales/orders/list/manage')
        }

        merge_deep(context.data.proforma.sales_order, result)
        if (!context.data.proforma.show_in_cases_or_bottles) {
            context.data.proforma.show_in_cases_or_bottles =
                context.data.proforma.sales_order.supplier.price_preference === 'bottle'
                    ? CasesOrBottles.bottles
                    : CasesOrBottles.cases
        }
    },
    async fetch_sales_order_with_items(sales_order_artkey: number) {
        const {result: sales_order} = await api.get<GetSalesOrderResponse>(`discover/sales-orders/${sales_order_artkey}`)
        merge_deep(context.data, {
            sales_order,
            stepper: {
                active: sales_order.combined_status as any,
            },
        })
    },
    rerender_proforma_pdf(save_settings: boolean): void {
        const account = pdf_helper.current_account()

        if (save_settings) {
            methods.save_proforma_pdf_settings()
        }
        pdf_helper.render_base64_encoded({
            sales_order: context.data.proforma.sales_order as SalesOrder,
            account: account,
            show_article_code: context.data.proforma.show_article_code,
            show_country_of_origin: context.data.proforma.show_country_of_origin,
            show_cbs_code: context.data.proforma.show_cbs_code,
            show_bottle_lot: context.data.proforma.show_bottle_lot,
            cases_or_bottles: context.data.proforma.show_in_cases_or_bottles || CasesOrBottles.cases,
            decimal_locale: context.data.proforma.show_decimal_point_as,
            show_liters_of_alcohol: context.data.proforma.show_liters_of_alcohol,
            group_similar_items: context.data.proforma.group_similar_items,
            proforma_or_invoice: ProformaInvoice.PROFORMA,
        })
    },
    async save_proforma_pdf_settings() {
        const {success} = await api.post('sales.invoice.update_invoice_comment', {
            artkey: context.data.sales_order.artkey,
            invoice_comment: context.data.proforma.sales_order.invoice_comment,
        })
        if (success) {
            notifier.notify('Settings for the Proforma saved', 'success')
        } else {
            notifier.notify('Could not save the settings for the Proforma', 'warning')
        }
    },
    async upsert_entity(entity_type: EntityType, data: any) {
        const entity = context.data.entities[entity_type]

        let endpoint = collection_so.entities[context.data.entity_type].endpoint
        let method = 'post'

        if (context.data.entity_artkey) {
            method = 'put'
            // The artkeys like SOI-123 need to be stripped when accessing the entity endpoints.
            const clean_artkey = context.data.entity_artkey.split('-')[1]
            endpoint = `${endpoint}/${clean_artkey}`
        }

        logger.debug(`[entity-${context.data.entity_type}] ${method} to ${endpoint}`)
        const {result, success} = await api[method](endpoint, data, true)
        // No need to wait for the result.
        methods.fetch_sales_order_items_summary()

        if (success) {
            // Clear the search filter/textarea after modifying an entity.
            collection_sellable.filters.search.selection = ''
            let target_name = ''
            if (entity_type === EntityType.SOA) {
                target_name = entity.description
            } else {
                target_name = entity.product_name
            }

            if (method === 'post') {
                notifier.notify(`${entity.title} "${target_name} added to sales order.`, 'info')
                await collection_so.reset_query()
                // Only clear the entity form context when adding a new item.
                context.reset_entity(entity_type)
            } else {
                notifier.notify(`${entity.title} "${target_name}" updated in sales order.`, 'info')
                await collection_so.update_item(entity.artkey)
            }
        } else {
            const detail = result.detail
            if (detail) {
                notifier.notify(detail, 'warning')
            } else {
                notifier.notify(`Something unexpected happened: ${result.message}`, 'danger')
            }
        }
    },
}

export const pdf_helper = new PDFHelper<ProformaInvoiceRendererAttrs>(proforma_or_invoice_renderer)
