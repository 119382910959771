import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {api, notifier} from '@bitstillery/common/app'
import {Button, FieldText} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {and, greater_than_or_equal_to, invalid_fields, invalid_fields_format, less_than_or_equal_to, validation, Validator} from '@bitstillery/common/lib/validation'

import {OfferItem} from '@/models/pricelists'

interface EditPositionAttrs {
    spotlight: OfferItem
    max: number
    onsuccess: () => void
}

export class EditSpotlightPosition extends MithrilTsxComponent<EditPositionAttrs> {

    data = proxy({
        current_position: 0,
        new_position: 1,
    })

    $v = {
        new_position: null! as Validator, // initialized in the constructor, vnode.attrs.max is needed.
    }

    constructor(vnode: m.Vnode<EditPositionAttrs>) {
        super()
        this.$v.new_position = validation([this.data, 'new_position'], and([
            greater_than_or_equal_to(1),
            less_than_or_equal_to(vnode.attrs.max),
        ]))
    }

    oncreate(vnode: m.Vnode<EditPositionAttrs>) {
        this.data.current_position = vnode.attrs.spotlight.spotlight_rank
        this.data.new_position = vnode.attrs.spotlight.spotlight_rank
    }

    async change_position(vnode: m.Vnode<EditPositionAttrs>) {
        const {status_code} = await api.post(`discover/offer-items/${vnode.attrs.spotlight.artkey}/spotlight/reposition`, {
            rank: this.data.new_position,
        }, true)
        if (status_code > 299) {
            notifier.notify('Could not set to the new position', 'danger')
            return
        }
        notifier.notify('Position updated', 'info')
        vnode.attrs.onsuccess()
    }

    view(vnode: m.Vnode<EditPositionAttrs>) {
        return (
            <div className='c-edit-spotlight-position'>
                <div className='form'>
                    <FieldText
                        disabled={true}
                        label="Current position"
                        model={[this.data, 'current_position']}
                        type="number"
                    />
                    <FieldText
                        label="New position"
                        model={[this.data, 'new_position']}
                        type="number"
                        max={vnode.attrs.max}
                        min={1}
                        validation={this.$v.new_position}
                    />
                    <Button
                        className="btn-submit"
                        disabled={invalid_fields(this.$v).length}
                        tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                        icon="save"
                        onclick={async() => {
                            await this.change_position(vnode)
                        }}
                        text={'Update position'}
                        type="success"
                    />

                </div>
            </div>
        )
    }
}
