import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Button,
    CellBottleAndCase,
    CellProduct,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Country,
    Icon,
    PanelFilters,
} from '@bitstillery/common/components'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {copy_object, merge_deep} from '@bitstillery/common/lib/utils'
import {reset_validation} from '@bitstillery/common/lib/validation'
import {api} from '@bitstillery/common/app'
import {format_money_with_symbol} from '@bitstillery/common/lib/format.ts'

import {ManageButtons} from '@/sales/orders/view/manage/components/buttons'
import {context, EntitySOI, EntityType} from '@/sales/orders/view/lib/context'
import {
    GetCountryOfOriginAvailabilitiesResponse,
    GetSalesOrderItemsCollectionViewResponse,
    GetSellableItemCollectionViewResponse,
} from '@/factserver_api/fact2server_api'
import {
    SalesOrderItemType,
    SellableItemFilterType,
} from '@/factserver_api/fact2server_api'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {OfferHistory} from '@/components/market_info/offer_history'
import {BottleSalesOrders} from '@/components/market_info/bottle_sales_orders'
import {GetLotAvailabilityResponse} from '@/factserver_api/fact2server_api'
import {CaseLabels} from '@/components/labels'
import {ItemTagsDropDownData} from '@/factserver_api/item_tags'

export const collection: CollectionProxy = new CollectionProxy()

export async function fetch_lots(case_artkey: number, country_of_origin?: string) {
    if (!case_artkey) {
        return
    }
    const entity = context.data.entities[context.data.entity_type] as EntitySOI
    const endpoint = `discover/sales-orders/${context.data.root_artkey}/products/lot-availabilities`
    const filters = {case_artkey} as any
    if (country_of_origin) {
        filters.country_of_origin = country_of_origin
    }
    const {result} = await api.get<GetLotAvailabilityResponse[]>(`${endpoint}?filters=${JSON.stringify(filters)}`)
    context.data.lots.splice(0, context.data.lots.length, ...result)
    if (context.data.lots.length === 1) {
        entity.item_artkey = context.data.lots[0].artkey
    } else {
        entity.item_artkey = '' as any
    }
}

function get_sellable_target(row: GetSellableItemCollectionViewResponse):any {
    if (row.sellable_item_type === SellableItemFilterType.Stock) {
        return {
            entity_type: EntityType.SOI,
            icon: 'stock',
            tip: 'Choose product from Stock',
            type: 'success',
        }
    } if (row.sellable_item_type === SellableItemFilterType.Purchase) {
        if (row.source_reference && row.source_reference.startsWith('P')) {
            return {
                entity_type: EntityType.SOTI,
                icon: 'tbo',
                tip: 'Choose product from a saved purchase order as TBO',
                type: 'surface',
            }
        } else {
            return {
                entity_type: EntityType.SOI,
                icon: 'purchase',
                tip: 'Choose product from a confirmed purchase order as Stock',
                type: 'info',
            }
        }
    } else if (row.sellable_item_type === SellableItemFilterType.TBO) {
        return {
            entity_type: EntityType.SOTI,
            icon: 'tbo',
            tip: 'Choose product from TBO',
            type: 'surface',
        }

    } else if (row.sellable_item_type === SellableItemFilterType.Market) {
        return {
            entity_type: EntityType.SOTI,
            icon: 'market',
            tip: 'Choose product from Market',
            type: 'surface',
        }
    } else if (row.sellable_item_type === SellableItemFilterType.Credit) {
        return {
            entity_type: EntityType.SOCI,
            icon: 'credit_item',
            tip: 'Choose product from this customer\'s creditable items',
            type: 'warning',
        }
    }
}

export async function select_row(row) {
    const sellable_item = copy_object(row)
    const sellable_target = get_sellable_target(sellable_item)

    let row_artkey
    if (sellable_item.artkey.includes('-')) {
        row_artkey = sellable_item.artkey.split('-')[1]
    } else {
        row_artkey = sellable_item.artkey
    }

    delete sellable_item.artkey
    delete sellable_item.country_of_origin

    if (sellable_target.entity_type !== context.data.entity_type) {
        context.data.entity_type = sellable_target.entity_type
        m.route.set(`/sales/orders/${context.data.root_artkey}/view/manage/${context.data.entity_type}`)
    }

    const entity: any = context.data.entities[context.data.entity_type]

    if (sellable_item.countries_of_origin) {
        context.data.countries_of_origin.splice(0, context.data.countries_of_origin.length, ...sellable_item.countries_of_origin)
    }
    merge_deep(context.data.entities[context.data.entity_type], sellable_item)

    if (sellable_target.entity_type === EntityType.SOCI) {
        entity.sales_order_item_artkey = row_artkey

        // Default to credit all cases & total value.
        merge_deep(entity, {
            number_of_cases: row.number_of_cases_available,
            number_of_cases_available: row.number_of_cases_available,
            total_value: row.euro_was_bought_for,
        })

        if (row.source_artkey) {
            entity.supplier_price_list_item_artkey = row.source_artkey
        }
    } else {
        reset_validation(context.$v[context.data.entity_type])
        // Notice that we use reference here; not to confuse with the
        // entity's artkey; that's reserved for editing existing SOI's.
        merge_deep(context.data.countries_of_origin, {
            options: row.availabilities,
            selection: '',
        })
        merge_deep(entity, {
            // _artkey: row.artkey,
            euro_was_bought_for: Number(row.euro_was_bought_for),
            // NOTE: Shouldn't Sellable must also have excise_per_case?
            excise_per_case: 0,
            number_of_cases: null,
            lot: row.source_reference,
            price_per_case: row.list_price,
        })

        if (sellable_target.entity_type === EntityType.SOI) {
            entity.item_artkey = null
            entity.country_of_origin = null
        } else if (sellable_target.entity_type === EntityType.SOTI) {
            // We do not use the purchase price as selling price.
            entity.price_per_case = null
            if (entity.sellable_item_type === SellableItemFilterType.Market) {
                entity.supplier_price_list_item_artkey = row_artkey
                entity.purchase_order_item_artkey = null
                entity.offer_item_artkey = null
            } else if (entity.sellable_item_type === SellableItemFilterType.Purchase) {
                entity.supplier_price_list_item_artkey = null
                entity.purchase_order_item_artkey = row_artkey
                entity.offer_item_artkey = null
            } else if (entity.sellable_item_type === SellableItemFilterType.TBO) {
                entity.supplier_price_list_item_artkey = null
                entity.purchase_order_item_artkey = null
                entity.offer_item_artkey = row.source_artkey
            }
        }
    }
    collection.select_one(row.artkey)
}

export const columns = [
    {
        name: 'Product',
        render: (row: GetSellableItemCollectionViewResponse) => {
            return <CellProduct
                {...row}
                additional_details={<CaseLabels
                    show_as_icons={true}
                    case={{
                        best_before_date: row.best_before_date,
                        serialized_item_tags: row.serialized_item_tags,
                        tax_label: row.tax_label,
                    }}
                />}
            />
        },
        width: '2fr',
    },
    {
        name: 'Quantity',
        render: (row) => <CellBottleAndCase
            bottle_text={row.number_of_cases_available ? `${row.number_of_bottles * row.number_of_cases_available} btl` : ''}
            case_text={row.number_of_cases_available ? `${row.number_of_cases_available} cs` : ''}
        />,
    },
    {
        name: 'List Price',
        render: (row) => {
            if (row.sellable_item_type === SellableItemFilterType.Market) {
                return <CellBottleAndCase
                    bottle_text={format_money_with_symbol(row.spli_price_per_case / row.number_of_bottles, row.spli_currency)}
                    case_text={format_money_with_symbol(row.spli_price_per_case, row.spli_currency)}
                />
            }
            return <CellBottleAndCase
                bottle_text={format_money_with_symbol(row.list_price_per_bottle, row.currency)}
                case_text={format_money_with_symbol(row.list_price, row.currency)}
            />
        },
    },
    {
        name: 'Source',
        render: (row) => <div className="td-group">
            <div className="header">{row.source}</div>
            <div className="fl fl-g05">{row.availabilities && row.availabilities.map(it =>
                <Country country_code={it.country_of_origin} />)
            }</div>
        </div>,
    },
]

export class CollectionSellable extends MithrilTsxComponent<unknown> {

    watchers:any = []

    async oninit() {
        const transforms = {
            items_queried: async({result}) => {
                if (!context.data.root_artkey) {
                    return
                }
                const case_artkeys = result.map(it => it.case_artkey).filter(it => it)
                const endpoint = `discover/sales-orders/${context.data.root_artkey}/products/country-of-origin-availabilities?case_artkeys=${case_artkeys.join(',')}`
                const {status_code, result: availability_data} = await api.get<GetCountryOfOriginAvailabilitiesResponse[]> (endpoint)
                if (status_code > 299) {
                    return
                }

                availability_data.forEach(({availabilities, case_artkey}) => {
                    const table_row = result.find(i => case_artkey === i.case_artkey)
                    if (table_row) {
                        table_row.countries_of_origin = availabilities
                    }
                })

                // Add item tags as a string, used in the drop down when searching items.
                result.forEach((sellable) => {
                    if (sellable.serialized_item_tags) {
                        sellable.serialized_item_tags_as_str = sellable.serialized_item_tags
                            .split(',')
                            .filter((tag_artkey) => tag_artkey !== '' && tag_artkey !== '0')
                            .map((tag_artkey) => ItemTagsDropDownData.get_item_tag_for_artkey(tag_artkey))
                            .filter((tag_artkey) => tag_artkey)
                            .map((tag_artkey) => tag_artkey.abbreviation)
                            .filter((tag_artkey) => tag_artkey)
                            .join(';')
                    }
                })

                return result
            },
        }
        await collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: `discover/sales-orders/${context.data.root_artkey}/products/collection-view`,
            },
            url_sync: false,
        }, undefined, transforms)
    }

    onremove() {
        this.watchers.map((unwatch) => unwatch())
    }

    row_separator(filter_type: SellableItemFilterType) {
        if (filter_type === SellableItemFilterType.Credit) return {icon: 'credit_item', text: 'Credit', type: 'warning'}
        if (filter_type === SellableItemFilterType.Market) return {icon: 'market', type: 'surface', text: 'Market'}
        if (filter_type === SellableItemFilterType.Purchase) return {icon: 'purchase', type: 'info', text: 'Purchase'}
        if (filter_type === SellableItemFilterType.Stock) return {icon: 'stock', type: 'success', text: 'Stock'}
        if (filter_type === SellableItemFilterType.TBO) return {icon: 'tbo', type: 'surface', text: 'TBO'}
    }

    view(): m.Children {
        return [
            <PanelFilters className="context-create" collection={collection}/>,
            <CollectionView className="context-create" mode="table">
                <ManageButtons collection={collection}/>
                <CollectionHeader collection={collection} columns={columns}/>
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    on_row_click={(row) => {
                        select_row(row)
                        fetch_lots(row.case_artkey)
                    }}
                    row_actions={(row: GetSalesOrderItemsCollectionViewResponse) => {
                        return [
                            <Button
                                icon="success"
                                tip={'Select this product'}
                                onclick={() => {
                                    select_row(row)
                                    fetch_lots(row.case_artkey)
                                }}
                                type="info"
                                variant="toggle"
                            />,
                        ]
                    }}
                    row_detail={(row: GetSalesOrderItemsCollectionViewResponse) => {
                        if ([SalesOrderItemType.AdditionalItem, SalesOrderItemType.Voucher].includes(row.sales_order_item_type)) {
                            return null
                        }
                        return [
                            <CaseInfoPanel
                                bottle_artkey={row.bottle_artkey}
                                ignore_ref={true}
                            />,
                            <div className="columns">
                                <div className="column is-12">
                                    <OfferHistory
                                        bottle_artkey={row.bottle_artkey}
                                        customs_status={row.case_customs_status}
                                    />
                                </div>
                            </div>,
                            <div className="columns">
                                <div className="column is-12">
                                    <BottleSalesOrders
                                        bottle_artkey={row.bottle_artkey}
                                        customs_status={row.case_customs_status}
                                        current_client_artkey={context.data.sales_order.supplier_artkey}
                                    />
                                </div>
                            </div>,
                        ]
                    }}
                    row_separator={(row: GetSellableItemCollectionViewResponse, index: number, items: GetSalesOrderItemsCollectionViewResponse[]) => {
                        if (index === 0) {
                            return this.row_separator(row.sellable_item_type)
                        }
                        const previous_item = items[index - 1] as any
                        if (row.sellable_item_type !== previous_item.sellable_item_type) {
                            return this.row_separator(row.sellable_item_type)
                        }
                    }}
                    row_status={(row) => {
                        const sellable = get_sellable_target(row)
                        return {
                            render: [
                                <Icon
                                    name={sellable.icon}
                                    tip={sellable.tip}
                                    type={sellable.type}
                                />,
                            ],
                            type: sellable.type,
                        }
                    }}
                />
            </CollectionView>,
        ]
    }
}
