/** llm:tested */
import m from 'mithril'
import {Amount, Button, FieldMoney, SubAmount} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {EditSpotlightPosition} from './components/edit_spotlight_position'

import inputs from '@/components/inputs'
import {$m, $s} from '@/app'
import {Collection} from '@/components/collection/collection'
import {CollectionTable} from '@/components/collection_table'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {Modal} from '@/components/modal/modal'
import {SearchBar} from '@/components/collection/search_bar'
import {AddToSalesOrder} from '@/offer/components/add_to_sales_order'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import api from '@/api'
import {
    formatDate,
    formatDateTime,
    formatPercentage,
    download_binary_file_from_base64_str,
    pluralize,
} from '@/_utils'
import {icon, icon_with_popover} from '@/components/icon'
import {icon_button} from '@/components/_buttons'

interface OfferRecord {
    artkey: string
    product_name: string
    product_category_name: string
    maximum_quantity: () => number
    minimum_quantity: () => number
    price_per_case: () => number
    delivery_period: () => number
    is_hidden: boolean
    spot_price: () => number
    spotlight_valid_hours: () => number
    edit: () => boolean
    spotlight_expiry_timestamp: () => string
    case_artkey: string
    case_customs_status: string
    item_best_before_date: string
    item_tags_sorted_artkeys: string[]
    bottle_artkey: string
    supplier_artkey: string
    offer_item_type: string
    case_number_of_bottles: number
    bottle_volume: number
    bottle_alcohol_percentage: number
    bottle_refill_status: string
    case_gift_box_type: string
    case_tax_label: string
    item_damages: string
    item_general_tags: string
    item_pack_size: string
    item_packaging: string
    euro_total_stock_value: number
    number_of_cases_in_stock: number
    number_of_cases_in_sales: number
    number_of_cases_available: number
    item_throughput: number
    avg_purchase_price: number
    case_excise_nl: number
    price_per_case_incl_excise: number
    spot_price_incl_excise: number
    margin_percentage: number
    list_price_per_bottle: number
    supplier_name: string
}

export class OfferSpotlight extends MithrilTsxComponent<any> {
    private offer: any
    private add_order: (val?: any) => any
    private search_bar_controller: any
    private product_management_api: ProductManagementApi
    private search_input_ctrl: any

    position_spotlight_item: OfferRecord | null = null

    constructor() {
        super()
        this.offer = new Collection({
            api_function_name: 'pricelist.get_offer_item_records',
            query_limit: 25,
            sort_order: [
                {name: 'case_number_of_bottles', direction: 'asc'},
                {name: 'bottle_volume', direction: 'asc'},
                {name: 'bottle_alcohol_percentage', direction: 'asc'},
                {name: 'bottle_refill_status', direction: 'desc'},
            ],
            default_sort_by: 'spotlight_rank',
            default_sort_order: 'asc',
            filter_serverside: true,
            additional_params: window.prop({
                only_show_type: ['purchase', 'stock', 'tbo'],
                only_spotlight: true,
            }),
            dont_reuse: true,
        })

        this.add_order = window.prop(null)
        this.search_bar_controller = null
        this.product_management_api = new ProductManagementApi()
    }

    submit_search(text: string): void {
        this.offer.update_search_term(text)
        this.offer.submit_search()
    }

    oncreate(): void {
        const query = m.route.param('q')
        if (query) {
            this.search_input_ctrl.submit_search(query.replace(/\+/g, ' '))
        } else {
            this.offer.init()
        }

        const q = m.route.param('q')
        if (q) {
            this.search_input_ctrl.submit_search(q.replace(/\+/g, ' '))
        }

        $m.common.observable.subscribe('stock_updated', this, () => this.offer.requery())
    }

    color_class(value: number): string {
        if (value > 8.8) {
            return '.analysis-good-color'
        } else if (value < 0) {
            return '.analysis-bad-color'
        } else {
            return ''
        }
    }

    handle_pricelist_result(resp: {success: boolean; result: string}): void {
        if (resp.success) {
            download_binary_file_from_base64_str(
                resp.result,
                'Offer ' + formatDate(new Date()) + '.xlsx',
            )
        } else {
            $m.common.generic_error_handler('Error handling pricelist result')
        }
    }

    save_offer_item(record: OfferRecord, callback: (timestamp: string) => void): void {
        const data = {
            artkey: record.artkey,
            maximum_quantity: record.maximum_quantity(),
            minimum_quantity: record.minimum_quantity(),
            sales_price_per_case: record.price_per_case(),
            delivery_period: record.delivery_period(),
            is_hidden: record.is_hidden,
            spot_price_per_case: record.spot_price(),
            spotlight_valid_hours: record.spotlight_valid_hours(),
        }

        api.call2('pricelist.update_offer_item', data, (resp) => {
            callback(resp.spotlight_expiry_timestamp)
        })
    }

    position_item(record: OfferRecord) {
        this.position_spotlight_item = record
    }

    move_spotlight_item(record: OfferRecord, direction: string, callback: (rank: number) => void): void {
        const data = {
            artkey: record.artkey,
            direction: direction,
        }

        api.call('spotlight.move_spotlight_item', data, (resp) => {
            if (resp.success) {
                callback(resp.rank)
            } else {
                $m.common.generic_error_handler('Error moving spotlight item')
            }
        })
    }

    delete_spotlight_item(record: OfferRecord, callback: () => void): void {
        const data = {
            artkey: record.artkey,
        }

        api.call('spotlight.delete_spotlight_item', data, (resp) => {
            if (resp.success) {
                callback()
            } else {
                $m.common.generic_error_handler('Error deleting spotlight item')
            }
        })
    }

    add_to_sales_order(record: OfferRecord): void {
        this.add_order(record)
    }

    close_add_to_order(): void {
        this.add_order(null)
    }

    close_position_dialog(): void {
        this.position_spotlight_item = null
    }

    refresh_and_close_add_to_order(): void {
        this.close_add_to_order()
    }

    view() {
        return (
            <div className="c-pricelist-spotlight view offer">
                {this.add_order() && (
                    <Modal
                        title={`Add ${this.add_order().product_name} to sales order`}
                        onclose={() => this.close_add_to_order()}
                    >
                        <AddToSalesOrder
                            offer_item={this.add_order()}
                            on_added_item={() => this.refresh_and_close_add_to_order()}
                        />
                    </Modal>
                )}
                {this.position_spotlight_item && (
                    <Modal
                        title={`Set position of ${this.position_spotlight_item?.product_name}`}
                        onclose={() => this.close_position_dialog()}
                    >
                        <EditSpotlightPosition
                            spotlight={this.position_spotlight_item}
                            max={this.offer.items().length}
                            onsuccess={() => {
                                this.position_spotlight_item = null
                                this.offer.requery()
                            }}
                        />
                    </Modal>
                )}

                <div className="c-filter-group">
                    <SearchBar
                        placeholder="Search products..."
                        on_submit={(text: string) => this.submit_search(text)}
                        default_search_text={this.offer.search_term()}
                        search_bar_controller={(ctrl: any) => this.search_bar_controller = ctrl}
                        on_get_suggestions$={(filter_text: string) =>
                            this.product_management_api.get_simple_product_names(filter_text)}
                    />
                </div>

                {this.offer.show_counter()}

                <CollectionTable
                    collection={this.offer}
                    options={{
                        search_table_style: true,
                        sticky_header: true,
                        with_buttons: false,
                        autoscale: true,
                        unique_name: 'offer_item_list',
                        row_classes: (record: OfferRecord) =>
                            record.is_hidden ? ['offer-item__is-hidden'] : [],
                    }}
                    row_model={(record: OfferRecord) => {
                        Object.assign(record, {
                            delivery_period: window.prop(record.delivery_period),
                            edit: window.prop(false),
                            maximum_quantity: window.prop(record.maximum_quantity),
                            minimum_quantity: window.prop(record.minimum_quantity),
                            old_delivery_period: record.delivery_period,
                            old_maximum_quantity: record.maximum_quantity,
                            old_minimum_quantity: record.minimum_quantity,
                            old_price_per_case: record.price_per_case,
                            price_per_case: window.prop(record.price_per_case),
                            spot_price: window.prop(record.spot_price),
                            spotlight_expiry_timestamp: window.prop(record.spotlight_expiry_timestamp),
                            spotlight_valid_hours: window.prop(record.spotlight_valid_hours),
                        })
                        return record
                    }}
                    view_details={(record: OfferRecord) => (
                        <CaseInfoPanel
                            case_artkey={record.case_artkey}
                            case_customs_status={record.case_customs_status}
                            item_best_before_date={record.item_best_before_date}
                            item_tags_sorted_artkeys={record.item_tags_sorted_artkeys}
                            bottle_artkey={record.bottle_artkey}
                            current_supplier_artkey={record.offer_item_type === 'tbo' ? record.supplier_artkey : null}
                        />
                    )}
                    columns={[
                        {
                            width: 8,
                            header: '',
                            name: 'Actions',
                            function: (record: OfferRecord) =>
                                <div className="btn-toolbar no-click">
                                    <div className="btn-group">
                                        {record.edit() ? [
                                            icon_button('ok', {
                                                class: 'btn-success no-click',
                                                onclick: () => {
                                                    this.save_offer_item(record, (timestamp) => {
                                                        if (record.old_price_per_case !== record.price_per_case()) {
                                                            $m.common.observable.broadcast('stock_updated')
                                                        }
                                                        record.spotlight_expiry_timestamp(timestamp)
                                                        record.old_price_per_case = record.price_per_case()
                                                        record.old_delivery_period = record.delivery_period()
                                                        record.old_maximum_quantity = record.maximum_quantity()
                                                        record.old_minimum_quantity = record.minimum_quantity()
                                                        record.edit(false)
                                                        notifier.notify('Successfully updated the offer item.', 'success')
                                                    })
                                                },
                                            }),
                                            icon_button('ban-circle', {
                                                class: 'btn-info no-click',
                                                onclick: () => {
                                                    record.price_per_case(record.old_price_per_case)
                                                    record.delivery_period(record.old_delivery_period)
                                                    record.maximum_quantity(record.old_maximum_quantity)
                                                    record.minimum_quantity(record.old_minimum_quantity)
                                                    record.edit(false)
                                                },
                                            }),
                                        ]
                                            : [
                                                icon_button('pencil', {
                                                    class: 'btn-default no-click',
                                                    disabled: record.is_hidden,
                                                    onclick: () => record.edit(true),
                                                }),
                                                icon_button('trash', {
                                                    class: 'btn-danger no-click',
                                                    onclick: () => this.delete_spotlight_item(record, () => this.offer.requery()),
                                                }),
                                                icon_button('shopping-cart', {
                                                    class: 'btn-default',
                                                    onclick: () => this.add_to_sales_order(record),
                                                }),
                                            ]
                                        }
                                    </div>
                                </div>,
                        },
                        {
                            width: 1,
                            name: 'Spotlight rank',
                            field: 'spotlight_rank',
                            sort: true,
                            function: (record: OfferRecord) => {
                                const rank = record.spotlight_rank
                                return <div className='fl fl-g1'>
                                    <div className='fl fl-g05'>
                                        <Button
                                            icon='chevronUp'
                                            size='s'
                                            onclick={() => {
                                                this.move_spotlight_item(record, 'up', () => this.offer.requery())
                                            }}
                                        />
                                        <Button
                                            icon='chevronDown'
                                            size='s'
                                            onclick={() => {
                                                this.move_spotlight_item(record, 'down', () => this.offer.requery())
                                            }}
                                        />
                                        <Button
                                            text={rank}
                                            size='s'
                                            className='no-click'
                                            onclick={() => {
                                                this.position_item(record)
                                            }}
                                        />
                                    </div>
                                </div>
                            },
                        },
                        {
                            width: 8,
                            name: 'Valid hours',
                            field: 'spotlight_valid_hours',
                            sort: true,
                            classes: ['number'],
                            function: (record: OfferRecord) => {
                                if (record.edit()) {
                                    return inputs.number(record.spotlight_valid_hours, {min: 1})
                                } else if (record.spotlight_valid_hours()) {
                                    return `${record.spotlight_valid_hours()} ${pluralize(record.spotlight_valid_hours(), 'hour', 'hours')}`
                                } else {
                                    return '-'
                                }
                            },
                        },
                        {
                            width: 2,
                            name: 'End time',
                            field: 'spotlight_expiry_timestamp',
                            sort: true,
                            transform: (timestamp: () => string) =>
                                timestamp() ? formatDateTime(timestamp()) : '-',
                        },
                        {
                            width: 4,
                            name: 'Article code',
                            field: 'case_article_code',
                            sort: true,
                        },
                        {
                            width: 12,
                            name: 'Product',
                            sort: true,
                            field: 'product_name',
                            ellipsis: true,
                        },
                        {
                            width: 5,
                            name: 'Category',
                            sort: true,
                            field: 'product_category_name',
                            ellipsis: true,
                            function: (record: OfferRecord) =>
                                <span className="text-capitalize">{record.product_category_name}</span>,
                        },
                        {
                            width: 5,
                            name: 'TBO supplier',
                            sort: true,
                            field: 'supplier_name',
                            ellipsis: true,
                        },
                        {
                            width: 3,
                            name: 'Btl / cs',
                            sort: true,
                            field: 'case_number_of_bottles',
                            default_visible: false,
                            classes: ['number'],
                        },
                        {
                            width: 4,
                            name: 'Size',
                            sort: true,
                            field: 'bottle_volume',
                            default_visible: false,
                            function: (record: OfferRecord) =>
                                <span>
                                    {(+record.bottle_volume).toFixed(1)}cl
                                </span>,
                            classes: ['number'],
                        },
                        {
                            width: 4,
                            name: 'Alc %',
                            sort: true,
                            field: 'bottle_alcohol_percentage',
                            default_visible: false,
                            function: (record: OfferRecord) =>
                                <span>
                                    {(+record.bottle_alcohol_percentage).toFixed(1)}%
                                </span>,
                            classes: ['number'],
                        },
                        {
                            width: 3,
                            name: 'Ref',
                            sort: true,
                            default_visible: false,
                            field: 'bottle_refill_status',
                        },
                        {
                            width: 10,
                            name: 'Specs',
                            sort: false,
                            function: (record: OfferRecord) => {
                                const specs = [
                                    record.case_number_of_bottles,
                                    (+record.bottle_volume).toFixed(1),
                                    (+record.bottle_alcohol_percentage).toFixed(1),
                                    record.bottle_refill_status,
                                ]
                                return specs.join(' / ')
                            },
                        },
                        {
                            width: 4,
                            name: 'GB',
                            sort: true,
                            field: 'case_gift_box_type',
                            default_visible: false,
                            ellipsis: true,
                        },
                        {
                            width: 4,
                            name: 'Tax Label',
                            sort: true,
                            field: 'case_tax_label',
                            default_visible: false,
                            ellipsis: true,
                        },
                        {
                            width: 5,
                            header: 'BBD',
                            name: 'Best before date',
                            sort: true,
                            field: 'item_best_before_date',
                            default_visible: false,
                            transform: (date: string) => date ? formatDate(date) : null,
                        },
                        {
                            width: 10,
                            name: 'Features',
                            function: (record: OfferRecord) => {
                                const features = []
                                if (record.case_gift_box_type) {
                                    features.push(record.case_gift_box_type)
                                }
                                if (record.case_tax_label) {
                                    features.push(record.case_tax_label)
                                }
                                if (record.item_best_before_date) {
                                    features.push(`BBD: ${formatDate(record.item_best_before_date)}`)
                                }
                                if (record.item_damages) {
                                    features.push(record.item_damages.replace(', ', ' / '))
                                }
                                if (record.item_general_tags) {
                                    features.push(record.item_general_tags.replace(', ', ' / '))
                                }
                                if (record.item_pack_size) {
                                    features.push(record.item_pack_size)
                                }
                                if (record.item_packaging) {
                                    features.push(record.item_packaging)
                                }
                                return features.join(' / ')
                            },
                        },
                        {
                            width: 3,
                            header: 'Cus.',
                            name: 'Customs status',
                            sort: true,
                            field: 'case_customs_status',
                        },
                        {
                            width: 6,
                            name: 'Stock value',
                            sort: true,
                            field: 'euro_total_stock_value',
                            classes: ['price'],
                            function: (record: OfferRecord) =>
                                record.offer_item_type !== 'tbo' ?
                                    <Amount amount={+record.euro_total_stock_value} currency={$s.currencies.default} /> : '-',
                        },
                        {
                            width: 2,
                            name: 'Number of cases in stock',
                            header: icon_with_popover({
                                iconId: 'home',
                                content: 'Number of cases in stock',
                            }),
                            sort: true,
                            field: 'number_of_cases_in_stock',
                            classes: ['number'],
                        },
                        {
                            width: 2,
                            name: 'Number of cases in sales',
                            header: icon_with_popover({
                                iconId: 'screenshot',
                                content: 'Number of cases in sales',
                            }),
                            sort: true,
                            field: 'number_of_cases_in_sales',
                            classes: ['number'],
                            default_visible: false,
                        },
                        {
                            width: 2,
                            name: 'Number of cases available',
                            header: icon_with_popover({
                                iconId: 'fa-shield-alt',
                                content: 'Number of cases available',
                            }),
                            sort: true,
                            field: 'number_of_cases_available',
                            classes: ['number'],
                        },
                        {
                            width: 3,
                            name: 'Throughput',
                            header: icon_with_popover({
                                iconId: 'flash',
                                content: 'Throughput',
                            }),
                            field: 'item_throughput',
                            sort: true,
                            classes: ['number'],
                            transform: (value: number) => value ? formatPercentage(value) : null,
                            default_visible: false,
                        },
                        {
                            width: 7,
                            header: 'Max Qty',
                            name: 'Maximum Quantity',
                            classes: ['number'],
                            function: (record: OfferRecord) => {
                                if (record.edit()) {
                                    return inputs.number(record.maximum_quantity, {min: 0})
                                } else if (record.maximum_quantity()) {
                                    return record.maximum_quantity()
                                } else {
                                    return '-'
                                }
                            },
                        },
                        {
                            width: 7,
                            header: 'MOQ',
                            name: 'Minimum Order Quantity',
                            classes: ['number'],
                            function: (record: OfferRecord) => {
                                if (record.edit()) {
                                    return inputs.number(record.minimum_quantity, {min: 0})
                                } else if (record.minimum_quantity()) {
                                    return record.minimum_quantity()
                                } else {
                                    return '-'
                                }
                            },
                        },
                        {
                            width: 5,
                            header: 'List Qty',
                            name: 'List Quantity',
                            field: 'list_quantity',
                            classes: ['number'],
                            function: (record: OfferRecord) =>
                                record.maximum_quantity() !== null ?
                                    Math.min(record.maximum_quantity(), record.number_of_cases_available) :
                                    record.number_of_cases_available,
                        },
                        {
                            width: 10,
                            name: 'Avg purchase / cs',
                            sort: true,
                            field: 'avg_purchase_price',
                            classes: ['price'],
                            function: (record: OfferRecord) => {
                                if (!record.avg_purchase_price) {
                                    return '-'
                                }
                                if ($s.include_excise) {
                                    return [
                                        <Amount
                                            amount={+record.avg_purchase_price + +record.case_excise_nl}
                                            currency={$s.currencies.default}
                                        />,
                                        <SubAmount
                                            label="Ex Duty"
                                            amount={record.avg_purchase_price}
                                            currency={$s.currencies.default}
                                        />,
                                    ]
                                }
                                return <Amount
                                    amount={record.avg_purchase_price}
                                    currency={$s.currencies.default}
                                />
                            },
                        },
                        {
                            width: 12,
                            name: 'Sales / cs',
                            sort: true,
                            field: 'price_per_case',
                            classes: ['price'],
                            function: (record: OfferRecord) => {
                                if (record.edit()) {
                                    return <FieldMoney
                                        currency={[$s.currencies, 'default']}
                                        model={[record, 'price_per_case']}
                                    />
                                }
                                if (+record.price_per_case()) {
                                    return <Amount
                                        amount={+record.price_per_case()}
                                        currency={$s.currencies.default}
                                    />
                                }
                                return '-'
                            },
                        },
                        {
                            width: 12,
                            name: 'Sales NL / cs',
                            sort: true,
                            field: 'price_per_case_incl_excise',
                            default_visible: $s.include_excise,
                            classes: ['price'],
                            function: (record: OfferRecord) => {
                                if (!+record.price_per_case_incl_excise) {
                                    return '-'
                                }
                                return [
                                    <Amount
                                        amount={+record.price_per_case_incl_excise}
                                        currency={$s.currencies.default}
                                    />,
                                    <SubAmount label="Duty"
                                        amount={record.case_excise_nl}
                                        currency={$s.currencies.default}
                                    />,
                                ]
                            },
                        },
                        {
                            width: 12,
                            name: 'Spot price / cs',
                            sort: true,
                            classes: ['price'],
                            field: 'spot_price',
                            function: (record: OfferRecord) => {
                                if (record.edit()) {
                                    return <FieldMoney
                                        currency={[$s.currencies, 'default']}
                                        model={[record, 'spot_price']}
                                    />
                                }
                                return <Amount
                                    amount={record.spot_price() ? +record.spot_price() : null}
                                    currency={$s.currencies.default}
                                />
                            },
                        },
                        {
                            width: 12,
                            name: 'Spot price NL / cs',
                            sort: true,
                            field: 'spot_price_incl_excise',
                            default_visible: $s.include_excise,
                            classes: ['price'],
                            function: (record: OfferRecord) => {
                                if (!+record.spot_price_incl_excise) {
                                    return '-'
                                }
                                return [
                                    <Amount
                                        amount={+record.spot_price_incl_excise}
                                        currency={$s.currencies.default}
                                    />,
                                    <SubAmount label="Duty"
                                        amount={record.case_excise_nl}
                                        currency={$s.currencies.default}
                                    />,
                                ]
                            },
                        },
                        {
                            width: 6,
                            name: 'Margin %',
                            sort: true,
                            field: 'margin_percentage',
                            classes: ['price'],
                            function: (record: OfferRecord) => {
                                const margin_percentage = record.edit() ?
                                    (record.price_per_case() - record.avg_purchase_price) / record.avg_purchase_price * 100 :
                                    +record.margin_percentage
                                return <span className={this.color_class(margin_percentage)}>{margin_percentage.toFixed(2)}%</span>
                            },
                        },
                        {
                            width: 6,
                            name: 'List price/btl',
                            sort: true,
                            field: 'list_price_per_bottle',
                            classes: ['price'],
                            default_visible: false,
                            function: (record: OfferRecord) =>
                                <Amount
                                    amount={+record.price_per_case() / +record.case_number_of_bottles}
                                    currency={$s.currencies.default}
                                />,
                        },
                        {
                            width: 6,
                            name: 'Delivery period',
                            sort: true,
                            field: 'delivery_period',
                            classes: ['number'],
                            function: (record: OfferRecord) => {
                                if (record.edit()) {
                                    return inputs.number(record.delivery_period, {min: 1})
                                } else if (record.delivery_period()) {
                                    return `${record.delivery_period()} ${pluralize(record.delivery_period(), 'week', 'weeks')}`
                                } else {
                                    return '-'
                                }
                            },
                        },
                        {
                            width: 3,
                            name: 'Item type',
                            sort: true,
                            field: 'offer_item_type',
                            transform: (type: string) => {
                                if (!type) return null
                                if (type === 'tbo') {
                                    return 'TBO'
                                }
                                return type.charAt(0).toUpperCase() + type.slice(1)
                            },
                        },
                        {
                            width: 2,
                            name: 'Product photo icon',
                            header: icon('fa-image'),
                            field: 'has_product_photo',
                            function: (record: OfferRecord) =>
                                record.has_product_photo ?
                                    icon_with_popover({
                                        iconId: 'fa-image',
                                        title: 'Product photo',
                                        content: 'This product has one or more photos.',
                                    }) : null,
                        },
                    ]}
                />
            </div>
        )
    }
}
