/** llm:production */
// eslint-disable-next-line
import m from 'mithril'
import {format_iso_to_date, format_money_responsive} from '@bitstillery/common/lib/format'
import {
    Amount,
    Button,
    ButtonDataCard,
    ButtonGroup,
    CellAvailability,
    CellDimension,
    CellProduct,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Icon,
    Link,
    Lot,
    PanelContext,
    PanelFilters,
    RowActionDownload,
    SubAmount,
} from '@bitstillery/common/components'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {object_to_query_string} from '@bitstillery/common/lib/utils'
import {$t, api} from '@bitstillery/common/app'

import {create_download_for_blob} from '@/factserver_api/api'
import * as discover_link from '@/components/discover_link'
import {BottleSalesOrders} from '@/components/market_info/bottle_sales_orders'
import {ItemSalesOrders} from '@/components/market_info/item_sales_orders'
import {StockItemPhotoList} from '@/stock/product_photos/stock_item_photo_list'
import {accountIconBySlug} from '@/accounts'
import {$s} from '@/app'
import {GetItemCollectionViewResponse} from '@/factserver_api/fact2server_api'
import {CaseLabels} from '@/components/labels'

const collection = new CollectionProxy()

const columns = [
    {
        name: 'Warehouse',
        render: (row: GetItemCollectionViewResponse) => {
            let lot_date, lot_type
            if (row.entry_date) {
                lot_date = format_iso_to_date(row.entry_date)
                lot_type = 'default'
            } else {
                lot_date = format_iso_to_date(row.expected_delivery_date)
                lot_type = 'warning'
            }

            return (
                <Lot
                    lot_date={lot_date}
                    lot_number={row.lot}
                    purchase_order_reference={row.purchase_order_reference}
                    stock_age={row.stock_age}
                    mutation={row.item_mutation_reference ? {
                        reference: row.item_mutation_reference,
                    } : undefined}
                    type={lot_type}
                    warehouse_name={row.warehouse_name}
                />
            )
        },
    },
    {
        name: 'Product',
        render: (row: GetItemCollectionViewResponse) => <CellProduct
            {...row}
            cases_per_pallet={undefined}
            cases_per_pallet_layer={undefined}
            best_before_date={undefined}
            bottle_gtin={row.bottle_gtin_code}
            additional_details={<CaseLabels
                show_as_icons={true}
                case={{
                    best_before_date: row.best_before_date,
                    serialized_item_tags: row.serialized_item_tags,
                    remark: row.remark,
                    tax_label: row.tax_label,
                }}
            />}
        />,
    },
    {
        name: 'Purchase',
        render: (row: GetItemCollectionViewResponse) => <div>
            <Link
                account={row.account_slug}
                href={`/purchase-orders/manage/${row.purchase_order_artkey}`}
                target="_blank"
            >
                <div className="fl-c">
                    {accountIconBySlug(row.account_slug, row.account_name)}
                    {row.purchase_order_reference}
                </div>
            </Link>
            <Amount
                amount={row.euro_was_bought_for}
                currency={$s.currencies.default}
                display_currency={$s.currencies.default}
            />
            <SubAmount
                label="Costs"
                amount={row.total_costs_per_case}
                currency={$s.currencies.default}
                display_currency={$s.currencies.default}
            />
        </div>,
    },
    {
        name: 'Sales',
        render: (row: GetItemCollectionViewResponse) => <div>
            <Amount
                amount={row.price_per_case}
                currency={$s.currencies.default}
                display_currency={$s.currencies.default}
            />
            <SubAmount
                label="Excise NL"
                amount={row.excise_nl_per_case}
                currency={$s.currencies.default}
                display_currency={$s.currencies.default}
            />
        </div>,
    },
    {
        name: 'Availability',
        render: (row: GetItemCollectionViewResponse) => <CellAvailability row={row}/>,
    },
    {
        name: 'Stock value',
        field: 'item_total_stock_value',
        classes: ['price'],
        render: (row: GetItemCollectionViewResponse) => (
            <Amount
                amount={+row.euro_total_stock_value}
                currency={$s.currencies.default}
                display_currency={$s.currencies.default}
            />
        ),
    },
    {
        name: 'Info',
        render: (row: GetItemCollectionViewResponse) => <div>
            <CellDimension
                cases_per_pallet={row.cases_per_pallet}
                cases_per_pallet_layer={row.cases_per_pallet_layer}
                height={row.case_height}
                length={row.case_length}
                width={row.case_width}
                weight={row.case_weight}
            />
        </div>,
    },
]

export class StockList {
    stock_statistics: any

    oninit() {
        this.query_stock_statistics()
        collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: 'discover/items/collection-view',
            },
        }, undefined, undefined)
    }

    async query_stock_statistics() {
        const {result} = await api.post('stock.get_statistics', {}, false) as any
        result.available_value = (result.in_purchase_value + result.in_stock_value - result.in_sales_value).toFixed(2)
        this.stock_statistics = result
    }

    async export_stock() {
        const {result} = await api.get<Blob>(`discover/items/export?${object_to_query_string(collection.state.query)}`)
        create_download_for_blob(result, 'stock.xlsx')
    }

    async download_batch(item: GetItemCollectionViewResponse) {
        const {result} = await api.get<Blob>(`discover/items/${item.artkey}/product-photos/download?include_internal=false`)
        create_download_for_blob(result, `${item.reference}-product-photos.zip`)
    }

    view() {
        return <div className="c-stock-list view-container">
            <PanelFilters collection={collection}/>
            <CollectionView mode="table">
                <ButtonGroup>
                    <ButtonDataCard/>
                    <Button
                        icon="excel"
                        onclick={async() => this.export_stock()}
                        text={$t('stock.actions.export_excel')}
                        type="info"
                        variant="context"
                    />
                </ButtonGroup>

                <CollectionHeader
                    collection={collection}
                    columns={columns}
                />
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    row_actions={(row: GetItemCollectionViewResponse) => {
                        const case_artkey = row.case_artkey
                        const supplier_artkey = row.supplier_artkey
                        const explore_url = supplier_artkey
                            ? `/market/explore?case=${case_artkey}&supplier=${supplier_artkey}`
                            : `/market/explore?case=${case_artkey}`
                        const discover_url = discover_link.from_properties2(row.product_name, row.volume, row.alcohol_percentage)

                        return [
                            <Button
                                icon="edit"
                                link={`/stock/manage/${row.artkey}`}
                                type="info"
                                variant="toggle"
                            />,
                            <Button
                                className={'no-click'}
                                icon="search"
                                link={discover_url}
                                tip={() => 'Open in discover'}
                                variant="toggle"
                            />,
                            <Button
                                className={'no-click'}
                                icon="compass"
                                link={explore_url}
                                tip={() => 'Open in explore'}
                                variant="toggle"
                            />,
                            row.number_of_product_photos > 0 ? <RowActionDownload
                                row_download={() => this.download_batch(row)}
                                row={row}
                                tip={'Download all photos in a zip file'}
                                mode="link"
                            /> : null,
                        ].filter((it) => it)
                    }}

                    row_detail={(row: GetItemCollectionViewResponse) => {
                        return <div className="columns">
                            <div className="column row-details">
                                <ItemSalesOrders item_artkey={row.artkey}/>
                                <BottleSalesOrders bottle_artkey={row.bottle_artkey}/>
                            </div>
                            <div className="column">
                                <StockItemPhotoList
                                    item_artkey={row.artkey}
                                    item_reference={row.reference}
                                    onProductPhotoCount={(number_of_photos) => {
                                        row.number_of_product_photos = number_of_photos
                                    }}
                                />
                            </div>
                        </div>
                    }}
                    row_status={(row: GetItemCollectionViewResponse) => {
                        return {
                            render: [
                                row.number_of_product_photos > 0 ?
                                    <Icon tip={() => `${row.number_of_product_photos} product photos`}
                                        name={'image'}/> : null,
                            ].filter((it) => it),
                        }
                    }}
                />
            </CollectionView>
            <PanelContext collection={collection}>
                <div className="content">
                    {this.stock_statistics && <div className="c-stock-context">
                        <div className="statistics-group vertical">
                            <div className="stat default">
                                <div className="amount">
                                    <Icon name="cart" type="unset"/>
                                    {format_money_responsive(this.stock_statistics.in_purchase_value, true, 'EUR')}
                                </div>
                                <div className="text">
                                    Total in purchase
                                </div>
                            </div>

                            <div className="stat info">
                                <div className="amount">
                                    <Icon name="stock" type="unset"/>
                                    {format_money_responsive(this.stock_statistics.in_stock_value, true, 'EUR')}
                                </div>
                                <div className="text">
                                    Total in stock
                                </div>
                            </div>

                            <div className="stat success">
                                <div className="amount">
                                    <Icon name="sales" type="unset"/>
                                    {format_money_responsive(this.stock_statistics.in_sales_value, true, 'EUR')}
                                </div>
                                <div className="text">
                                    Total in sales
                                </div>
                            </div>

                            <div className="stat default">
                                <div className="amount">
                                    <Icon name="company" type="unset"/>
                                    {format_money_responsive(this.stock_statistics.available_value, true, 'EUR')}
                                </div>
                                <div className="text">
                                    Total Available
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </PanelContext>
        </div>
    }
}
